import { paths as userProductPaths } from "src/apps/modules/userProduct/utils";
import { paths as partnerBrandPaths } from "src/apps/modules/partnerBrand/utils";
import { paths as partnerCategoryPaths } from "src/apps/modules/partnerCategory/utils";
import { paths as partnerProductPaths } from "src/apps/modules/partnerProduct/utils";
import { paths as partnerManualPaths } from "src/apps/modules/partnerManual/utils";
import { paths as partnerWarrantyPaths } from "src/apps/modules/partnerWarranty/utils";
import { paths as partnerUserPaths } from "src/apps/modules/partnerUser/utils";

type RoleConfigDetail = { roles: PartnerRole[] };
type RoleConfig = { [path: string]: RoleConfigDetail };

const disabled: RoleConfigDetail = {
  roles: []
};

const allowAdmin: RoleConfigDetail = {
  roles: ["ROLE_ADMIN"]
};

const allowOfficers: RoleConfigDetail = {
  roles: ["ROLE_ADMIN", "ROLE_OFFICER"]
};

const allowSettings: RoleConfigDetail = {
  roles: ["ROLE_ADMIN", "ROLE_SETTING"]
};

const allowAnyone: RoleConfigDetail = {
  roles: ["ROLE_ADMIN", "ROLE_OFFICER", "ROLE_SETTING"]
};

const roleConfig: RoleConfig = {
  "/": allowAnyone,

  [userProductPaths.index]: allowOfficers,
  [userProductPaths.edit]: allowOfficers,

  [partnerBrandPaths.index]: allowSettings,
  [partnerBrandPaths.create]: allowSettings,
  [partnerBrandPaths.edit]: allowSettings,

  [partnerCategoryPaths.index]: allowSettings,

  [partnerProductPaths.index]: allowSettings,
  [partnerProductPaths.create]: allowSettings,
  [partnerProductPaths.edit]: allowSettings,
  [partnerProductPaths.import]: allowSettings,

  [partnerManualPaths.index]: allowSettings,
  [partnerManualPaths.create]: allowSettings,
  [partnerManualPaths.edit]: allowSettings,

  [partnerWarrantyPaths.index]: allowSettings,
  [partnerWarrantyPaths.create]: allowSettings,
  [partnerWarrantyPaths.edit]: allowSettings,

  [partnerUserPaths.index]: allowAdmin,
  [partnerUserPaths.edit]: allowAdmin,
  [partnerUserPaths.changePassword]: allowAdmin,
  [partnerUserPaths.changeMyPassword]: allowAnyone,

  // TODO: ^ define allowed roles for new pages here
  disabled: disabled
};

export default roleConfig;

export const checkHasRole = (
  path: string,
  userRoles?: PartnerRole[]
): boolean => {
  if (!userRoles || userRoles.length === 0) return false;

  const allowedRoles = roleConfig[path].roles ?? [];
  return userRoles.some(r => allowedRoles.includes(r));
};
