const constants = {
  label: "สินค้า"
};

const paths = {
  index: "/partnerProduct",
  create: "/partnerProduct/create",
  edit: "/partnerProduct/edit",
  import: "/partnerProduct/import"
};

export { constants, paths };
