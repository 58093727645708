import { createAction, createReducer, ActionType } from "typesafe-actions";
import { put, call } from "redux-saga/effects";
import * as AuthApi from "./api";
import { ILoginReq, IJwt, IPartnerUserInfo } from "./types";
import { HttpError } from "src/utils/apiUtils";
import { STORAGE_AUTH_NAME } from "src/constants";

export const Actions = {
  loginRequest: createAction("auth/loginRequest")<ILoginReq>(),
  loginSuccess: createAction("auth/loginSuccess")<IJwt>(),
  loginFailure: createAction("auth/loginFailure")<string>(),
  updateJwtRequest: createAction("auth/updateJwtRequest")<IJwt | null>(),
  updateJwtSuccess: createAction("auth/updateJwtSuccess")(),
  logoutRequest: createAction("auth/logoutRequest")()
};

export function* loginSaga(
  authApi: typeof AuthApi,
  action: ReturnType<typeof Actions.loginRequest>
): Generator {
  try {
    const jwtInfo = (yield call(authApi.login, action.payload)) as IJwt;
    yield call(
      { context: localStorage, fn: "setItem" },
      STORAGE_AUTH_NAME,
      JSON.stringify(jwtInfo)
    );
    yield put(Actions.loginSuccess(jwtInfo));

    //Trigger storage change event on current tab
    window.dispatchEvent(new Event("storage"));
  } catch (e) {
    if (e instanceof HttpError) {
      yield put(Actions.loginFailure(e.message));
    }
  }
}

export function* logoutSaga(): Generator {
  yield call({ context: localStorage, fn: "removeItem" }, STORAGE_AUTH_NAME);

  //Trigger storage change event on current tab
  window.dispatchEvent(new Event("storage"));
}

export function* updateJwtSaga(
  action: ReturnType<typeof Actions.updateJwtRequest>
): Generator {
  const jwtInfo = action.payload;
  if (jwtInfo !== null) {
    yield call(
      { context: localStorage, fn: "setItem" },
      STORAGE_AUTH_NAME,
      JSON.stringify(jwtInfo)
    );
  } else {
    yield call({ context: localStorage, fn: "removeItem" }, STORAGE_AUTH_NAME);
  }
}

interface StoreType {
  readonly loggingIn: boolean;
  readonly token?: string;
  readonly tokenExp?: number;
  readonly userInfo?: IPartnerUserInfo;
  readonly errorMsg?: string;
}

const initialState: StoreType = {
  loggingIn: false
};

export const reducer = createReducer<StoreType, ActionType<typeof Actions>>(
  initialState
)
  .handleAction(Actions.loginRequest, state => ({
    ...state,
    loggingIn: true,
    token: undefined,
    tokenExp: undefined,
    errorMsg: undefined
  }))
  .handleAction(Actions.loginSuccess, (state, action) => {
    const { userInfo, token, exp } = action.payload;
    return {
      ...state,
      loggingIn: false,
      token: token,
      tokenExp: exp,
      userInfo: userInfo
    };
  })
  .handleAction(Actions.loginFailure, (state, action) => ({
    ...state,
    loggingIn: false,
    token: undefined,
    tokenExp: undefined,
    userInfo: undefined,
    errorMsg: action.payload
  }))
  .handleAction(Actions.updateJwtRequest, (state, action) => ({
    ...state,
    userInfo: action.payload?.userInfo,
    token: action.payload?.token,
    tokenExp: action.payload?.exp
  }))
  .handleAction(Actions.updateJwtSuccess, state => state);
