import React from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { NextPage } from "next";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "src/theme";
import { wrapper } from "src/configs/stores";
import { MainLayout } from "src/apps/commons/layouts";
import AccessDenied from "src/apps/commons/layouts/components/AccessDenied";
import { MuiPickersUtilsProviderWithThLocale } from "src/providers";
import { Alert } from "src/apps/modules/alert";
import { isServer, STORAGE_AUTH_NAME } from "src/constants";
import { IJwt } from "src/apps/modules/auth/types";
import { Actions } from "src/apps/modules/auth/stores";
import roleConfig from "../apps/modules/auth/roleConfig";

type PageWithLayoutType = NextPage<{ err: unknown }> & {
  Layout: typeof MainLayout;
};

type AppLayoutProps = {
  Component: PageWithLayoutType;
  err: unknown;
};

const WrappedApp: React.FC<AppLayoutProps> = ({ Component, err }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [authInfo, setAuthInfo] = React.useState(() => {
    try {
      const authInfoJson = localStorage.getItem(STORAGE_AUTH_NAME);
      return authInfoJson ? (JSON.parse(authInfoJson) as IJwt) : null;
    } catch (e) {
      return null;
    }
  });

  const handleLocalStorageChanged = () => {
    const authInfoJson = localStorage.getItem(STORAGE_AUTH_NAME);
    setAuthInfo(authInfoJson ? (JSON.parse(authInfoJson) as IJwt) : null);
  };

  const handleSyncAuthStore = React.useCallback(
    (jwt: IJwt | null) => {
      dispatch(Actions.updateJwtRequest(jwt));
    },
    [dispatch]
  );

  React.useEffect(() => {
    window.addEventListener("storage", handleLocalStorageChanged);
    return () =>
      window.removeEventListener("storage", handleLocalStorageChanged);
  }, []);

  React.useEffect(() => {
    if (authInfo?.token && router.pathname === "/auth") {
      router.push("/");
    } else if (!authInfo?.token && router.pathname !== "/auth") {
      router.push("/auth");
    }
    handleSyncAuthStore(authInfo);
  }, [authInfo, router.pathname, router, handleSyncAuthStore]);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const Layout = Component.Layout ?? React.Fragment;

  const partnerName = authInfo?.userInfo?.partner?.name;
  const allowedRoles = roleConfig[router.pathname]?.roles ?? [];
  const userRoles = authInfo?.userInfo?.roles ?? [];
  const alwaysAllowed = isServer || router.pathname === "/auth";
  const hasRole =
    alwaysAllowed || userRoles.some(r => allowedRoles.includes(r));

  return (
    <>
      <Head>
        <title>
          {partnerName ? `${partnerName} – Remynd` : `Remynd Partner`}
        </title>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link href="/fonts/styles.css" rel="stylesheet" />
        <link rel="shortcut icon" href="/images/cotto-favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProviderWithThLocale>
          <Layout>
            {hasRole ? (
              <Component err={err} />
            ) : (
              <AccessDenied allowedRoles={allowedRoles} userRoles={userRoles} />
            )}
          </Layout>
        </MuiPickersUtilsProviderWithThLocale>
        <Alert />
      </ThemeProvider>
    </>
  );
};

export default wrapper.withRedux(WrappedApp);
