import { all, takeEvery } from "redux-saga/effects";
import {
  Actions as AuthActions,
  loginSaga,
  logoutSaga,
  updateJwtSaga
} from "src/apps/modules/auth/stores";

import * as AuthApi from "src/apps/modules/auth/api";

function* rootSaga() {
  yield all([
    takeEvery(AuthActions.loginRequest, loginSaga, AuthApi),
    takeEvery(AuthActions.logoutRequest, logoutSaga),
    takeEvery(AuthActions.updateJwtRequest, updateJwtSaga)
  ]);
}

export default rootSaga;
