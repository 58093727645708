import { fetchJson } from "src/utils/apiUtils";
import { ILoginReq, IJwt } from "./types";

const endpoint = "login";

export const login = async (req: ILoginReq) => {
  return fetchJson<IJwt>(`${endpoint}`, {
    method: "POST",
    body: JSON.stringify({ ...req }),
    noAuth: true
  });
};
