const constants = {
  label: "ข้อมูลการรับประกัน"
};

const paths = {
  index: "/partnerWarranty",
  create: "/partnerWarranty/create",
  edit: "/partnerWarranty/edit"
};

export { constants, paths };
