import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { partnerRoleMap } from "src/utils/enum";

const AccessDenied: React.FC<{
  allowedRoles: PartnerRole[];
  userRoles: PartnerRole[];
}> = props => {
  const { allowedRoles, userRoles } = props;

  return (
    <div
      style={{
        position: "relative",
        top: "0",
        height: "100vh",
        padding: "8px 16px"
      }}
    >
      <Alert severity="error" variant="outlined">
        <AlertTitle>Access Denied</AlertTitle>
        {allowedRoles.length === 0 && `หน้าจอนี้ยังไม่เปิดให้เข้าถึงได้`}
        {allowedRoles.length > 0 && (
          <>
            <span>{`ต้องการสิทธิ์ : ${allowedRoles
              .map(r => partnerRoleMap[r])
              .join(" | ")}`}</span>
            <br />
            <span>{`คุณมีสิทธิ์ : ${userRoles
              .map(r => partnerRoleMap[r])
              .join(", ")}`}</span>
          </>
        )}
      </Alert>
    </div>
  );
};

export default AccessDenied;
