import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const MuiPickersUtilsProviderWithThLocale: React.FC<unknown> = props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {props.children}
    </MuiPickersUtilsProvider>
  );
};

export default MuiPickersUtilsProviderWithThLocale;
