type EnumMap<T extends string> = { [value in T]: string };
type EnumList<T extends string> = Array<{ value: T; label: string }>;

function listToMap<T extends string>(
  list: EnumList<T>
): { [value in T]: string } {
  const initial = {} as EnumMap<T>;
  return list.reduce((map, obj) => {
    map[obj.value] = obj.label;
    return map;
  }, initial);
}

export const warrantyContacts: EnumList<WarrantyContact> = [
  { value: "PARTNER", label: "มี ดูแลการรับประกันเอง" },
  { value: "MANUFACTURER", label: "มี การรับประกันเป็นของผู้ผลิต" },
  { value: "NONE", label: "ไม่มี" }
];
export const warrantyContactMap = listToMap(warrantyContacts);

export const startConditions: EnumList<StartCondition> = [
  { value: "DATE_OF_PURCHASE", label: "นับจากวันที่ซื้อ" },
  { value: "DATE_OF_INSTALLATION", label: "นับจากวันที่ติดตั้ง" },
  { value: "DATE_OF_DELIVERY", label: "นับจากวันที่ส่งมอบงาน" }
];
export const startConditionMap = listToMap(startConditions);

export const warrantyTypes: EnumList<WarrantyType> = [
  { value: "DURATION", label: "กำหนดระยะเวลา" },
  { value: "LIFETIME", label: "ตลอดอายุการใช้งาน" }
];
export const warrantyTypeMap = listToMap(warrantyTypes);

export const repeatUnitTypes: EnumList<RepeatUnit> = [
  { value: "DAY", label: "วัน" },
  { value: "WEEK", label: "สัปดาห์" },
  { value: "MONTH", label: "เดือน" },
  { value: "YEAR", label: "ปี" }
];
export const repeatUnitTypeMap = listToMap(repeatUnitTypes);

export const partnerRoles: EnumList<PartnerRole> = [
  { value: "ROLE_ADMIN", label: "Admin" },
  { value: "ROLE_OFFICER", label: "Officer" },
  { value: "ROLE_SETTING", label: "Setting" }
];
export const partnerRoleMap = listToMap(partnerRoles);

export const excelStatuses: EnumList<ExcelStatus> = [
  { value: "ADD", label: "Add" },
  { value: "UPDATE", label: "Update" },
  { value: "UNTOUCHED", label: "Untouched" }
];
export const excelStatusesMap = listToMap(excelStatuses);
