import { createAction, createReducer, ActionType } from "typesafe-actions";
import { AlertProps, AlertType } from "./types";

export const Actions = {
  alertRequest: createAction("alert/request")<AlertProps>(),
  alertClear: createAction("alert/clear")()
};

interface StoreType {
  type?: AlertType;
  messages: string[];
}
const initialState: StoreType = {
  messages: []
};
export const reducer = createReducer<StoreType, ActionType<typeof Actions>>(
  initialState
)
  .handleAction(Actions.alertRequest, (_, action) => ({
    type: action.payload.type,
    messages: action.payload.messages
  }))
  .handleAction(Actions.alertClear, () => initialState);
