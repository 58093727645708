import {
  IPartnerCategoryLv1Brief,
  IPartnerCategoryLv2Brief,
  IPartnerCategoryTree,
  IPartnerCategoryTreeLv1,
  IPartnerCategoryTreeLv2,
  IPartnerCategoryTreeLv3,
  PartnerCategoryFlatNode,
  PartnerCategoryFlatNodeLv1,
  PartnerCategoryFlatNodeLv2,
  PartnerCategoryFlatNodeLv3,
  PartnerCategoryTreeNode
} from "./types";

const constants = {
  label: "หมวดสินค้า"
};

const paths = {
  index: "/partnerCategory"
};

export { constants, paths };

// ---- Sorting --------------------------------------------------------------

export type CategorySortColumn =
  | "nameTh"
  | "active"
  | "createdAt"
  | "updatedAt";

const selectorMap: Record<
  CategorySortColumn,
  (node: PartnerCategoryTreeNode) => string
> = {
  nameTh: node => node.nameTh,
  active: node => (node.active ? "1" : "0"),
  createdAt: node => node.createdAt,
  updatedAt: node => node.updatedAt
};

type Comparator = (
  a: PartnerCategoryTreeNode,
  b: PartnerCategoryTreeNode
) => number;

export const sortTree = (
  tree: IPartnerCategoryTree,
  column: CategorySortColumn,
  desc: boolean
): IPartnerCategoryTree => {
  const selector = selectorMap[column];
  const comparator: Comparator = (a, b) => {
    let val = selector(a).localeCompare(selector(b));
    if (val === 0) {
      val = a.nameTh.localeCompare(b.nameTh);
    }
    return desc ? -val : val;
  };

  const sortedLv1s = [...tree.roots].sort(comparator).map(lv1 => {
    if (lv1.children.length === 0) return lv1;
    const sortedLv2s = [...lv1.children].sort(comparator).map(lv2 => {
      if (lv2.children.length === 0) return lv2;
      const sortedLv3s = [...lv2.children].sort(comparator);
      return { ...lv2, children: sortedLv3s };
    });
    return { ...lv1, children: sortedLv2s };
  });

  return { roots: sortedLv1s };
};

// ---- Convert / Filter -----------------------------------------------------

const treeToFlatLv1 = (
  lv1: IPartnerCategoryTreeLv1
): PartnerCategoryFlatNodeLv1 => {
  const displayLabel = `${lv1.code} : ${lv1.nameTh} (${lv1.nameEn})`;
  return {
    level: 1,
    displayLabel: displayLabel,
    searchLabels: [displayLabel.toUpperCase()],
    childCounts: [
      lv1.children.length,
      lv1.children.filter(lv2 => lv2.active).length
    ],
    ...lv1
  };
};
const treeToFlatLv2 = (
  lv2: IPartnerCategoryTreeLv2,
  searchLabels: string[]
): PartnerCategoryFlatNodeLv2 => {
  const displayLabel = `${lv2.code} : ${lv2.nameTh} (${lv2.nameEn})`;
  return {
    level: 2,
    displayLabel: displayLabel,
    searchLabels: [displayLabel.toUpperCase(), ...searchLabels],
    childCounts: [
      lv2.children.length,
      lv2.children.filter(lv3 => lv3.active).length
    ],
    ...lv2
  };
};
const treeToFlatLv3 = (
  lv3: IPartnerCategoryTreeLv3,
  lv1Id: PartnerCategoryLv1Id,
  searchLabels: string[]
): PartnerCategoryFlatNodeLv3 => {
  const displayLabel = `${lv3.code} : ${lv3.nameTh} (${lv3.nameEn})`;
  return {
    level: 3,
    displayLabel: displayLabel,
    searchLabels: [displayLabel.toUpperCase(), ...searchLabels],
    childCounts: [0, 0],
    lv1Id,
    ...lv3
  };
};

export const convertTreeToFlat = (
  tree: IPartnerCategoryTree
): PartnerCategoryFlatNode[] => {
  const { roots } = tree;
  return roots.flatMap(lv1 => {
    const ttf1 = treeToFlatLv1(lv1);
    return [
      ttf1,
      ...lv1.children.flatMap(lv2 => {
        const ttf2 = treeToFlatLv2(lv2, ttf1.searchLabels);
        return [
          ttf2,
          ...lv2.children.map(lv3 =>
            treeToFlatLv3(lv3, lv1.id, ttf2.searchLabels)
          )
        ];
      })
    ];
  });
};

const isMatchActive = (item: PartnerCategoryFlatNode, active?: boolean) => {
  return active == null || item.active === active;
};

const isMatchSearchTerm = (
  item: PartnerCategoryFlatNode,
  searchTerm?: string
) => {
  const query = searchTerm?.toUpperCase();
  return !query || item.searchLabels.some(t => t.includes(query));
};

export const filterListItems = (
  items: PartnerCategoryFlatNode[],
  collapsedLv1: Record<PartnerCategoryLv1Id, boolean>,
  collapsedLv2: Record<PartnerCategoryLv2Id, boolean>,
  searchTerm?: string,
  active?: boolean
): PartnerCategoryFlatNode[] => {
  let filtered = items;

  // Filter by criteria
  const hasCriteria = active != null || searchTerm;
  if (hasCriteria) {
    filtered = [];
    let keepLv2 = false;
    let keepLv1 = false;
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];
      if (item.level === 3) {
        const match =
          isMatchActive(item, active) && isMatchSearchTerm(item, searchTerm);
        if (match) {
          keepLv2 = true;
          keepLv1 = true;
          filtered.push(item);
        }
      } else if (item.level === 2) {
        const match =
          keepLv2 ||
          (isMatchActive(item, active) && isMatchSearchTerm(item, searchTerm));
        if (match) {
          keepLv1 = true;
          filtered.push(item);
          keepLv2 = false;
        }
      } else if (item.level === 1) {
        const match =
          keepLv1 ||
          (isMatchActive(item, active) && isMatchSearchTerm(item, searchTerm));
        if (match) {
          filtered.push(item);
          keepLv1 = false;
        }
      }
    }
    filtered.reverse();
  }

  // Filter by collapsed state
  return filtered.filter(it => {
    if (it.level === 2 || it.level === 3) {
      if (collapsedLv1[it.lv1Id]) return false;
    }
    if (it.level === 3) {
      if (collapsedLv2[it.lv2Id]) return false;
    }
    return true;
  });
};

export const nodeToDropdownLv1 = (
  item: PartnerCategoryFlatNodeLv1
): IDropdown<IPartnerCategoryLv1Brief> => {
  return {
    value: item.id,
    label: item.label,
    meta: {
      id: item.id,
      code: item.code,
      nameTh: item.nameTh,
      nameEn: item.nameEn,
      label: item.label,
      active: item.active
    }
  };
};

export const nodeToDropdownLv2 = (
  item: PartnerCategoryFlatNodeLv2
): IDropdown<IPartnerCategoryLv2Brief> => {
  return {
    value: item.id,
    label: item.label,
    meta: {
      id: item.id,
      lv1Id: item.lv1Id,
      code: item.code,
      nameTh: item.nameTh,
      nameEn: item.nameEn,
      label: item.label,
      active: item.active
    }
  };
};

export const describeCat = (cat?: PartnerCategoryFlatNode): string => {
  if (!cat) return "[ไม่ระบุหมวดหมู่]";
  return (
    `Level: ${cat.level}\n` +
    `Code: ${cat.code}\n` +
    `Name (TH): ${cat.nameTh}\n` +
    `Name (EN): ${cat.nameEn}\n` +
    `Active: ${String(cat.active)}\n\n` +
    (cat.level < 3
      ? `จำนวนหมวดย่อย Lv${cat.level + 1}: ${cat.childCounts[0]} total (${
          cat.childCounts[1]
        } active)`
      : "")
  );
};
