import { applyMiddleware, createStore, Middleware, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createWrapper } from "next-redux-wrapper";
import rootReducers from "./rootReducers";
import rootSagas from "./rootSagas";

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducers, bindMiddleware([sagaMiddleware]));

  store.sagaTask = sagaMiddleware.run(rootSagas);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
