import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, SnackbarCloseReason } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Actions } from "../stores";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AutoHideDuration = 6000;

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { type, messages } = useSelector((state: RootStore) => state.alerts);
  const open = type !== undefined && !!messages && messages.length > 0;
  const isMultipleMsg = (messages?.length ?? 0) > 1;

  // Save the last value of `type` for showing correct alert color during exit animation
  // after calling alertClear().
  const [severity, setSeverity] = useState(type);
  useEffect(() => {
    if (type && type !== severity) setSeverity(type);
  }, [type, severity]);

  const handleCloseSnackbar = (
    _: React.SyntheticEvent,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(Actions.alertClear());
  };

  const handleClose = (_: React.SyntheticEvent) => {
    dispatch(Actions.alertClear());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={isMultipleMsg ? undefined : AutoHideDuration}
      onClose={handleCloseSnackbar}
    >
      <Alert severity={severity} onClose={handleClose}>
        {isMultipleMsg ? (
          <ul style={{ margin: 0, padding: "0 0 0 16px" }}>
            {messages.map((m, i) => (
              <li key={i}>{m}</li>
            ))}
          </ul>
        ) : (
          messages[0]
        )}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
