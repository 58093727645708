const constants = {
  label: "คู่มือ/ไฟล์แนบ"
};

const paths = {
  index: "/partnerManual",
  show: "/partnerManual/show",
  create: "/partnerManual/create",
  edit: "/partnerManual/edit"
};

export { constants, paths };
