const constants = {
  label: "User Product"
};

const paths = {
  index: "/userProduct",
  edit: "/userProduct/edit"
};

export { constants, paths };

const countDaysOfMonth = (y: number, m: number) => {
  if (m == 2) {
    const isLeapYear = (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
    return isLeapYear ? 29 : 28;
  } else if (m === 4 || m === 6 || m === 9 || m === 11) {
    return 30;
  } else {
    return 31;
  }
};

export const calcEndDateFromDuration = (
  startDate?: string,
  y?: number,
  m?: number,
  d?: number
): string | undefined => {
  if (!startDate) return undefined;
  if (!y && !m && !d) return undefined;

  try {
    const sd = new Date(startDate);

    let pm = (m ?? 0) % 12;
    if (pm < 0) pm += 12;
    const py = (y ?? 0) + ((m ?? 0) - pm) / 12;

    let ey = sd.getFullYear() + (py ?? 0);
    let em = 1 + sd.getMonth() + (pm ?? 0); // 1..12
    if (em > 12) {
      em -= 12;
      ey += 1;
    }
    let ed = sd.getDate() + (d ?? 0); // 1..31

    let maxD = countDaysOfMonth(ey, em);
    while (ed > maxD) {
      ed -= maxD;
      em += 1;
      if (em > 12) {
        em = 1;
        ey += 1;
      }
      maxD = countDaysOfMonth(ey, em);
    }

    // Minus 1 day
    if (ed > 1) {
      ed -= 1;
    } else {
      if (em > 1) {
        em -= 1;
      } else {
        em = 12;
        ey -= 1;
      }
      ed = countDaysOfMonth(ey, em);
    }

    const endDate = `${ey}-${em < 10 ? `0${em}` : em}-${
      ed < 10 ? `0${ed}` : ed
    }`;

    if (endDate.length !== 10) {
      return undefined; // something weird happened
    }

    return endDate;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
