const constants = {
  label: "Partner User"
};

const paths = {
  index: "/partnerUser",
  edit: "/partnerUser/edit",
  changePassword: "/partnerUser/changePassword",
  changeMyPassword: "/partnerUser/changeMyPassword"
};

export { constants, paths };
